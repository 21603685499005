.App {
  text-align: center;
  display: flex;
  flex-direction: column;
  align-content: center;
  
}


.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}


img{
  max-width: 400px;
  margin: 0 auto;
}

.pet {
  display: flex;
  flex-direction: row;
  align-self: baseline;
  
}
.users {
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  justify-content: baseline;
  
}
ul {
  list-style-type: none;
  
}